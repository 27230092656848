<template>
    <div id="MyHome">
        <div class="content">
      <img src="../assets/logo.jpg" alt="App Logo" class="logo">
      <h1>乐村淘PDA测试版</h1>
      <p>
        欢迎使用乐村淘供配PDA测试版
      </p>
            
      <button @click="downloadApp" class="download-btn">立即下载</button>
    </div>
    <div v-if="showWeChatPopup" class="wechat-popup">
      <div class="popup-content">
        <img src="../assets/tishi.jpg" alt="WeChat Popup" class="popup-image">
        <button @click="closePopup" class="close-btn">关闭</button>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  name: 'PDATest',
  data() {
    return {
      showWeChatPopup: false
    };
  },
  methods: {
    downloadApp() {
      
      if (this.isWeChatBrowser()) {
        this.showWeChatPopup = true;
      } else {
        window.location.href = 'https://api.gxlctl.com/pda_test.apk';
      }
     
    },
    isWeChatBrowser() {
      return /MicroMessenger/i.test(navigator.userAgent);
    },
    closePopup() {
      this.showWeChatPopup = false;
    }
  },
  mounted() {
    if (this.isWeChatBrowser()) {
      this.showWeChatPopup = true;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
}

.logo {
  width: 150px;
  height: auto;
}

.download-btn {
  background-color: #4CAF50;
  color: white;
  padding: 15px 40px; /* 调大按钮的内边距 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px; /* 调大按钮的文字大小 */
}

.download-btn:hover {
  background-color: #45a049;
}

.wechat-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.close-btn:hover {
  background-color: #45a049;
}
</style>