// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import App from '../views/Home.vue';
import Statistics from '../views/Admin.vue';
import CodeHome from '../views/CodeHome.vue';
import UserAgreement from '../views/AppUserAgreement.vue';
import AppTermOfService from '../views/AppTermOfService.vue';
import StationCode from '../views/StationCode.vue';
import Test from '../views/Test.vue';
import PDATest from '../views/PDATest.vue';
import PDA from '../views/PDA.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: App
  },
  {
    path: '/pdatest',
    name: 'PDATest',
    component: PDATest
  },{
    path: '/pda',
    name: 'PDA',
    component: PDA
  },
   {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/code',
    name: 'CodeHome',
    component: CodeHome
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement
  },
  {
    path: '/AppTermOfService',
    name: 'AppTermOfService',
    component: AppTermOfService
  },
  {
    path: '/StationCode',
    name: 'StationCode',
    component: StationCode
  }
];

const router = new Router({
  mode: 'history', // 使用 HTML5 History 模式
  base: process.env.BASE_URL,
  routes
});

export default router;